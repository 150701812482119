<template>
	<div v-if="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="formState" @finish="onSearch">
				<a-row>
					<a-form-item label="卖品名称" name="mainTitle" class="ui-form__item">
						<a-input v-model:value="formState.mainTitle" placeholder="请输入卖品名称"/>
					</a-form-item>

          <a-form-item label="卖品分类" name="classifyId" class="ui-form__item">
            <a-select placeholder="请选择卖品分类" v-model:value="formState.classifyId" allow-clear style="width: 180px;">
              <a-select-option v-for="item in classifyList" :vlaue="item.id" :key="item.id"> {{ item.title }}</a-select-option>
            </a-select>
          </a-form-item>

					<a-form-item label="卖品状态" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择卖品状态" allow-clear style="width: 190px;">
							<a-select-option :value="0">已启用</a-select-option>
							<a-select-option :value="1">已禁用</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="卖品类型" name="type" class="ui-form__item">
						<a-select v-model:value="formState.type" placeholder="请选择卖品类型" allow-clear style="width: 190px;">
							<a-select-option :value="1">非套餐</a-select-option>
							<a-select-option :value="5">套餐</a-select-option>
						</a-select>
					</a-form-item>
          <a-form-item label="创建时间"  name="createTime" class="ui-form__item">
            <a-range-picker v-model:value="formState.createTime"></a-range-picker>
          </a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<a-button v-permission="['snack_manage_template_add']" type="primary" @click="onAdd">新增卖品</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button type="primary" html-type="submit">搜索</a-button>
						<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			<a-table style="margin-top: 10px;" :columns="columns" :dataSource="list" rowKey="id" :pagination="pagination" :scroll="{ x: 1200 }">
				<template #bodyCell="{ column, record }">
					
					<template v-if="column.key === 'image'">
						<a-image :src="record.imgUrl" style="width: 60px;"></a-image>
					</template>
					
					<template v-if="column.key === 'type'">
						<a-tag v-if="record.type === 1" color="blue">单品</a-tag>
						<a-tag v-if="record.type === 5" color="green">套餐</a-tag>
					</template>
					
					<template v-if="column.key === 'createTime'">
						{{ transDateTime(record.createTime) }}
					</template>
					<template v-if="column.key === 'isDisabled'">
            <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
            <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
					</template>
					<template v-else-if="column.key === 'action'">
						<a-dropdown :trigger="['click', 'hover']">
							<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
							</a-button>
							<template #overlay>
								<a-menu>
									<div v-permission="['snack_manage_template_see']" @click="onSee(record)">
										<a-menu-item>
											查看
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_template_edit']" @click="onEdit(record)">
										<a-menu-item>
											编辑
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_template_delete']" @click="onDelete(record)">
										<a-menu-item>
											删除
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_template_open']" v-if="record.isDisabled === 1" @click="onDisabled(record)">
										<a-menu-item>
											启用
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_template_disabled']" v-else @click="onDisabled(record)">
										<a-menu-item>
											禁用
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_template_shelves']" @click="onShowGrounding(record)">
										<a-menu-item>
											上架到影院
										</a-menu-item>
									</div>
								</a-menu>
							</template>
						</a-dropdown>
					</template>
				</template>
			</a-table>

      <a-modal v-model:visible="showGrounding" destroyOnClose title="上架到影院" width="500px" @ok="onSubmit">
        <a-spin :spinning="loading">
          <a-form ref="addForm" scrollToFirstError :model="modelRef" name="addForm" :labelCol="{span: 5}" :wrapperCol="{span: 15 }">
            <a-form-item label="影院名称" name="cinemaId" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-select placeholder="请选择影院名称" v-model:value="modelRef.cinemaId" style="width: 250px;">
                <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item v-if="modelRef.type === 1" label="库存" name="stock" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-input-number :min="0" :precision="0" style="width: 250px;" v-model:value="modelRef.stock" placeholder="请输入库存"/>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
		</a-spin>
	</div>
	<publish v-else :id="id" :isEdit="isEdit" :isSee="isSee" @back="onBack"></publish>
</template>

<script>
	import {Icon} from '@/components/icon/icon.js';
	import publish from '@/views/snackManage/goods/publish/publish.vue';
	import { getGoodsAllClassifyList } from '@/service/modules/mall.js';
  import { getCinemaAllList } from '@/service/modules/cinema.js';
	import { getGoodsList, deleteGoods, pullOffShelvesGoods, pullOnShelvesGoods, saveStoreGoods } from '@/service/modules/goods.js';
	export default {
		components: { Icon, publish },
		data() {
			return {
        modelRef: {},
        goodsId: 0,
        showGrounding: false,
				loading: false,
				showModal: false,
				organizationList: [],
				cinemaAllList: [],
				classifyList: [],
				formState: {},
				searchData: {},
				selectedListData: [],
				list: [],
				columns: [{
          title: '卖品名称',
          dataIndex: 'mainTitle',
        }, {
					title: '卖品图片',
					key: 'image',
					width: 130
				}, {
					title: '卖品分类',
					dataIndex: 'classifyName',
				}, {
					title: '卖品类型',
					key: 'type',
					width: 150
				}, {
					title: '销售价(元)',
					dataIndex: 'nowPrice',
					width: 150
				}, {
          title: '总销量',
          dataIndex: 'soldNum',
        }, {
					title: '创建时间',
					key: 'createTime',
					width: 200
				}, {
					title: '状态',
					key: 'isDisabled',
					width: 100
				},{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				isSee: false,
				id: 0,
				type: 1
			}
		},
		created() {
      this.getData();
      this.getAllCinemaList();
			this.getAllClassifyList();
		},
		methods: {
      onShowGrounding(item) {
        this.modelRef = JSON.parse(JSON.stringify(item));
        this.showGrounding = true;
        this.goodsId = item.id;
        this.modelRef.type = item.type;
      },
      async onSubmit() {
        this.$refs.addForm.validateFields().then(async () => {
          this.loading = true;
          let postData = JSON.parse(JSON.stringify(this.modelRef));
          delete postData.id;
          postData.goodsId = this.goodsId;
          try {
            let ret = await saveStoreGoods(postData);
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.showGrounding = false;
            }
          } catch(e) {
            this.loading = false;
          }
        })
      },
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
        delete this.formState.minNowPrice;
        delete this.formState.maxNowPrice;
				this.onSearch();
			},
			async getData() {
        if(this.searchData.createTime && this.searchData.createTime.length === 2) {
          this.searchData.createStartTime = this.moment(this.formState.createTime[0].startOf('day')).unix();
          this.searchData.createEndTime = this.moment(this.formState.createTime[1].endOf('day')).unix();
        }
        delete this.searchData.createTime;
				this.loading = true;
				try {
					let ret = await getGoodsList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						saleType: 2,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllClassifyList() {
				let ret = await getGoodsAllClassifyList({
					classifyType: 2
				});
				if (ret.code === 200) {
					let classifyList = ret.data.filter(item => {
						item.children = ret.data.filter(subItem => {
							return subItem.parentId === item.id;
						});
						return !item.parentId
					});
					this.classifyList = classifyList;
				}
			},
      async getAllCinemaList() {
        this.loading = true;
        try {
          let ret = await getCinemaAllList({
            isDisabled: 0
          });
          this.loading = false;
          if(ret.code === 200) {
            this.cinemaAllList = ret.data;
          }
        } catch(e) {
          this.loading = false;
        }
      },
			onAdd() {
				this.type = 1;
				this.isEdit = false;
				this.isSee = false;
				this.id = 0;
				this.showModal = true;
			},
			onEdit(item){
				this.type = 1;
				this.isEdit = true;
				this.isSee = false;
				this.id = item.id;
				this.showModal = true;
			},
			onSee(item) {
				this.type = 1;
				this.isEdit = true;
				this.isSee = true;
				this.id = item.id;
				this.showModal = true;
			},
			onCopy(item) {
				this.type = 1;
				this.isEdit = false;
				this.id = item.id;
				this.showModal = true;
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确定' + (item.isDisabled ? '启用' : '禁用') + '该卖品模板吗？',
					onOk: async ()=> {
						let ret;
						this.loading = true;
						try {
							if (item.isDisabled) {
								ret = await pullOnShelvesGoods({
									id: item.id
								})
							} else {
								ret = await pullOffShelvesGoods({
									id: item.id
								})
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗?',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await deleteGoods({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功!');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
